.container {
  display: flex;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}
.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 50px;
}
.logoButton {
  background: none;
  border: none;
}
.title {
  margin: 0 0 160px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.08em;
}
.titleLight {
  margin: 0 0 30px;
}
.subTitle {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.08em;
}
.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleWrapper h2 {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 94px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
}

.navigation {
  display: flex;
  justify-content: space-between;
}
.navigationButton {
  width: 330px;
  div {
    margin-bottom: 30px;
  }
  img {
    width: auto;
    height: 160px;
  }
}
