.titleWrap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.navigation {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.navigationFilter {
  flex: 1;
}
.container {
  overflow: hidden;
  overflow-y: auto;
}
.content {
  display: flex;
  flex: 1;
}
.membersListContainer {
  display: flex;
  padding: 25px;
  justify-content: center;
  margin: -25px 0 0 -25px;
  width: calc(100% + 25px);
  flex-wrap: wrap;
  & > * {
    margin: 25px 0 0 25px;
  }
}
.confirmRemoveFromGroup {
  padding: 0 30px;
  p {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
  }
}
@media (min-width: 768px) {
  .titleWrap {
    height: 60px;
  }
  .whiteButton {
    width: 200px;
    height: 60px;
  }
  .redButton {
    width: 200px;
    height: 60px;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
  }
  .addGuestButton {
    margin-right: 20px;
  }
  .membersListContainer {
    // padding: 60px 0;
  }
  .navigationButton {
    width: 200px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .whiteButton {
    width: 240px;
    height: 60px;
  }
  .redButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1200px) {
  .titleWrap {
    height: 110px;
  }
  .title {
    font-size: 26px;
    line-height: 30px;
  }
  .addGuestButton {
    width: 110px;
    height: 110px;
    top: 144px;
    right: 70px;
  }
  .membersListContainer {
    margin-top: 90px;
  }
  .navigationButton {
    width: 360px;
    height: 90px;
  }
  .whiteButton {
    width: 360px;
    height: 90px;
  }
  .redButton {
    width: 360px;
    height: 90px;
  }
}

.addGuestBtn {
  position: absolute;
  right: 36px;
  top: -15px;
  &Wrapper {
    padding-top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
