.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.title {
  display: block;
  margin: 0 0 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
}
.img {
  display: block;
  margin-bottom: 20px;
}
.description {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.message {
  margin: 0 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  color: #ffffff;
}

.paymentQrCode {
  margin-top: 50px;
}

.okBtn {
  width: 600px;
  height: 80px;
  border-radius: 10px 10px 0 0;
  font-size: 22px;
  line-height: 26px;
}
