.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.subtotal {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.tax {
  margin: 0 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.balance {
  margin: 0 0 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
  .value {
  }
}
.description {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}
.qr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContainer {
  display: flex;
}

.navigateBtn {
  width: 250px;
  height: 60px;
  border-radius: 10px 10px 0 0;
  font-size: 22px;
  line-height: 26px;
}

.text2PayBtn {
  width: 280px;
  img {
    margin-right: 15px;
  }
}
.payWithCardBtn {
  width: 280px;
  margin-bottom: 25px;
  img {
    margin-right: 15px;
  }
}
