.navigation {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.navigationFilter {
  flex: 1;
}
.container {
  overflow: hidden;
  overflow-y: auto;
}
.content {
  display: flex;
  flex: 1;
}
.membersListContainer {
  display: flex;
  padding: 25px;
  justify-content: center;
  margin: -25px 0 0 -25px;
  width: calc(100% + 25px);
  flex-wrap: wrap;
  & > * {
    margin: 25px 0 0 25px;
  }
}
.confirmRemoveFromGroup {
  padding: 0 30px;
  p {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
  }
}
@media (min-width: 768px) {
  .navigationButton {
    width: 200px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
