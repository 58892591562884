.quickProductView {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}
.quickProductViewHeader {
  height: 110px;
  display: flex;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
}
.quickProductViewHeaderLS {
  display: flex;
  height: 100%;
  flex: 1;
  padding-left: 20px;
  align-items: center;
  h2 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.06em;
    color: #000000;
  }
}
.quickProductViewHeaderRS {
  display: flex;
  height: 100%;
  padding-right: 20px;
  align-items: center;
}
.price {
  &Inner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: url('../../assets/icons/red-ticket.svg') no-repeat;
    background-size: cover;
  }
  &Value {
    font-style: normal;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.07em;
    color: #ffffff;
  }
}
.quickProductViewContent {
  flex: 1;
  display: flex;
  padding: 0 20px;
}
.quickProductViewInformation {
  position: relative;
  display: flex;
  height: 100%;
  &Left {
    margin-right: 20px;
  }
  &Right {
    position: relative;
    display: flex;
    flex: 1;
  }
}
.productCover {
  margin-bottom: 20px;
  img {
    display: block;
    width: 150px;
  }
}
.productDescription {
  flex: 1;
}
.changeQuantity {
  position: absolute;
  right: 0;
  bottom: 15px;
}

.navigationPanel {
  border-top: 60px solid transparent;
}
//.quickProductViewFooterLS {
//  flex: 1;
//}
//.quickProductViewFooterRS {
//}

.сustomizeButton {
  width: 100%;
  height: 60px;
}
.quickProductDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: #4b4b4b;
}

@media (min-width: 768px) {
  .quickProductView {
    width: 650px;
    height: 500px;
  }
  .quickProductViewHeader {
    height: 60px;
  }
  .quickProductViewHeaderLS {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .price {
    &Inner {
      width: 113px;
      height: 41px;
    }
    &Value {
      font-size: 22px;
      line-height: 30px;
    }
  }
  //.navigationButton {
  //  width: 200px;
  //  height: 60px;
  //  touch-action: manipulation;
  //}
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .quickProductView {
    width: 800px;
    height: 500px;
  }
  .quickProductViewHeader {
    height: 110px;
  }
  .quickProductViewHeaderLS {
    h2 {
      font-size: 34px;
      line-height: 40px;
    }
  }
  .price {
    &Inner {
      width: 240px;
      height: 88px;
    }
    &Value {
      font-size: 26px;
      line-height: 30px;
    }
  }
  .whiteButton {
    width: 300px;
    height: 80px;
  }
}
