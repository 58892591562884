.productItemWrap {
  gap: 25px;
}

.productItem {
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 60px rgba(26, 20, 63, 0.1);
  //border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  border-bottom:1px solid #D9D9D9;
  &Active {
    box-shadow:0 0 10px #f00;
    z-index: 2;
  }
}

.productItemCover {
  height: 11vw;
  overflow: hidden;
}

.productItemCover img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  object-fit: cover;
}

.productItemPrice {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1458vw;
  line-height: 1.458vw;
  text-align: center;
  letter-spacing: 0.06em;
  color: #df151c;
  margin: 0;
}

.productItemTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 5.3vw;
}

.productItemTitle {
  color: #000;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.358vw;
  line-height: 1.5625vw;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 0 0 5px 0;
}
