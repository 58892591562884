.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size: cover;
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
}
.separator {
  width: 80px;
  height: 1px;
  margin: 25px 0;
  background: #ffffff;
  opacity: 0.4;
}
.mainMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.8);
}
.mb15 {
  margin-bottom: 15px;
}
.errorMessage {
  color: #fff;
}
.mb30 {
  margin-bottom: 30px;
}
.fieldWithDescription {
  position: relative;
  margin-bottom: 30px;
}
.fieldDescription {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.06em;
  color: rgba(255, 255, 255, 0.6);
}
.fieldDescriptionLeft {
  position: absolute;
  justify-content: flex-end;
  top: 0;
  left: -215px;
  width: 200px;
  bottom: 0;
}
.fieldDescriptionRight {
  position: absolute;
  top: 0;
  right: -215px;
  width: 200px;
  bottom: 0;
}
.navigation {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.cancelButton {
  position: absolute;
  bottom: 0;
  left: 0;
}
.saveButton {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 768px) {
  .cancelButton {
    width: 240px;
    height: 60px;
  }
  .saveButton {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .cancelButton {
    width: 360px;
    height: 90px;
  }
  .saveButton {
    width: 360px;
    height: 90px;
  }
}
