.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.contentWaiver {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f2f2;
}
.waiverAgreementTextContainer {
  width: calc(100% - 40px);
  margin: 0 20px 20px;
}
.waiverAgreementTextTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementTextText {
  overflow: hidden;
  padding: 5px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreementSignatureContainer {
  margin: 0 20px;
}
.waiverAgreementSignatureTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementSignatureCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}
.waiverAgreementSignatureCanvasInner {
  border: 1px solid #595959;
  box-shadow: 0px 0px 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
}

.navigation {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.navigationFilter {
  flex: 1;
}
.navigation {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.navigationFilter {
  flex: 1;
}
.iAgreeTextbox {
  height: 75px;
  overflow: hidden;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: 0px 15px 60px rgba(26, 20, 63, 0.1);
  > div {
    padding: 10px;
    color: #595959;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
  }
}
@media (min-width: 768px) {
  .navigationButton {
    width: 200px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .navigationButton {
    width: 360px;
    height: 90px;
  }
}
