.addGuestButton {
  z-index: 5;
  background: transparent;
  &Wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 25px 32px 0;
  }
  &Icon {
    &:after,
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      background-color: #ffffff;
    }
    &:after {
      width: 26px;
      height: 4px;
      transform: translate(-50%, -50%);
    }
    &:before {
      width: 4px;
      height: 26px;
      transform: translate(-50%, -50%);
    }
    background: #df151c;
    border-radius: 50%;
    font-size: 0;
    color: #FFFFFF;
    position: relative;
    margin-right: 15px;
  }
  &Text {
    font-size: 28px;
    color: #df151c;
    border-bottom: 3px solid #df151c;
    line-height: 28px;
    padding-bottom: 2px;
  }
}

@media (min-width: 768px) {
  .addGuestButton {
    &Icon {
      width: 60px;
      height: 60px;
    }
  }
}
