.wizardFormPicture {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoContainer {
  width: 550px;
  height: 550px;
  padding: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  box-sizing: border-box;
}
.videoContainerInner {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-shadow: 0px 70px 70px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  // transform: translate3d(0, 0, 0);
}
.canvas {
  position: absolute;
  top: 9999px;
  right: 9999px;
}
.photo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  object-fit: cover;
  z-index: 10;
}
.button {
  position: fixed;
  bottom: 0;
}
@media (min-width: 768px) {
  .wizardFormPicture {
    padding-bottom: 60px;
  }
  .button {
    width: 240px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .wizardFormPicture {
    padding-bottom: 90px;
  }
  .button {
    width: 360px;
    height: 90px;
  }
}
