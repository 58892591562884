.waiverAgreement {
  margin: 0 20px 20px;
}
.waiverAgreementSignatureContainer {
  margin: 0 20px;
}
.waiverAgreementSignatureTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: #000000;
}
.waiverAgreementSignatureCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #df151c;
    color: white;
    border: 0;
    padding: 15px 30px;
    cursor: pointer;
    text-transform: uppercase;
    z-index: 100;
  }
}
.waiverAgreementSignatureCanvasInner {
  border: 1px solid #595959;
  box-shadow: 0 0 30px rgba(26, 20, 63, 0.1);
  border-radius: 5px;
}
