.cartSummary {
  display: flex;
  flex-direction: column;
}
.cartSummaryRow {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.cartSummaryTitle {
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.06em;
  color: #959595;
}
.cartSummaryTitleTotal {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.cartSummaryPrice {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.06em;
  color: #959595;
}
.cartSummaryPriceTotal {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
@media (min-width: 768px) {
  .cartSummary {
    padding: 20px 15px 0;
  }
  .cartSummaryPrice {
  }
  .cartSummaryPriceTotal {
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .cartSummary {
    padding: 30px 50px 0;
  }
}
