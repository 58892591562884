.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  input {
    font-size: 18px;
  }
}
.contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorIcon {
}
.separator {
  width: 80px;
  height: 1px;
  margin: 25px 0;
  background-color: #ffffff;
  opacity: 0.4;
}
.qwe {
  display: flex;
}
.subtotal {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.tax {
  margin: 0 0 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  .value {
    color: #ffffff;
  }
}
.balance {
  margin: 0 0 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #ffffff;
  .value {
  }
}
.description {
  margin: 0 0 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
  }
}
.iframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  & > div > input[type="text"]:not([value=""]) {
    font-family: Password;
  }
}
.formSelect {
  width: 100%;
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}

.formSelectWrap {
  width: 170px;
}

.formWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  gap: 20px;
}

.text2payContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.continueBtn {
  display: block;
  width: 500px;
  height: 60px;
  color: #e3353b;
  background-color: #fff;
  border: none;
}
.payButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payBtn {
  width: 500px;
  height: 65px;
}



.footerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymentOnlineBtn {
  width: 500px;
  height: 80px;
  border-radius: 10px 10px 0 0;

  font-size: 22px;
  line-height: 26px;
}

.errorAlert {
  width: 600px;
  background-color: #FFFFFF;
}
.errorAlertContainer {
  display: flex;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.errorAlertTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}
.errorAlertMessage {
  margin: 0 0 20px;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;
}

.navigation {
  position: relative;
  &Text2payBtn {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &PayBtn {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}


.errorAlertBtn {
  width: 100%;
  height: 80px;
}

.navigationText2payBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 240px;
  height: 60px;
}
.navigationPayBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 240px;
  height: 60px;
}
